.accountHolderDetailsContainer {
	/* background-color: red; */
	width: auto;
	min-height: 80vh;
	text-align: center;
	padding-top: 3vh;
	padding-bottom: 3vh;
}

.profileHeaderContainer h1 {
	font-size: max( 1.5vw, 24px );
	font-weight: 500;
	color: #3D5DAA;
}

.profileHeaderContainer p {
	font-size: max( 0.8vw, 14px );
	font-weight: 400;
	color: #666666;
	margin: 0;
}

.profileDetailsContainer {
	min-width: 40vw;
	width: 100%;
}

.profileDetailsCard {
	margin-top: 2vh;
	background-color: #ECEFF7;
	padding: max(2vw, 32px);
	border-radius: 5px;
	text-align: left;
	font-size: max( 1vw, 16px );
	color: #454545;
	width: 100%;
}

.profileDetailsCard .passwordChangeButton {
	color: #3D5DAA;
	cursor: pointer;
}

.passwordFormCard {
	background-color: red;
	padding: max( 2vw, 32px );
	border-radius: 5px;
	background-color: #ECEFF7;
	display: block;
	width: 100%;
}

.passwordFormCard input {
	width: 100%;
	height: max( 3vw, 48px );
	margin-bottom: 2vh;
	border-radius: 5px;
	background-color: white;
	padding-left: 1vw;
	border: none;
	font-size: max( 1vw, 16px );
}

.passwordFormCard button {
	width: max( 8vw, 120px );
	margin-right: 1.5vw;
}