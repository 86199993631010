.loginFormContainer {
	padding-top: max( 4vh, 64px );
	width: 100%;	
}

.loginFormContainer .loginLogoContainer {
	width: 100%;
	text-align: center;
}

.loginLogoContainer img {
	padding: max(1vw, 16px);
	width: max(20vw, 250px);
	height: auto;
}

.loginFormInputContainer {
	margin: auto;
	width: max(30vw, 480px);
	text-align: center;
	padding: max(1vw, 16px);
}

.loginFormInputContainer h1 {
	font-size: max( 1.5vw, 18px );
	color: #3D5DAA;
	margin-bottom: 20px;
}

.loginFormInput {
	border: none;
	padding-top: max(0.5vw, 8px);
	padding-bottom: max(0.5vw, 8px);
}

.loginFormInput input {
	width: 100%;
	padding-left: max(1vw, 16px);
	height: max(7vh, 50px);
	border: none;
	outline: none;
	border-radius: 10px;
	font-size: max(1vw, 16px);
	background-color: rgb(236, 239, 247);
}


.keepSignedInInput {
	/* background-color: red; */
	text-align: left;
	cursor: pointer;
}

.keepSignedInInput label {
	padding-left: max(0.5vw, 8px);
	font-size: max(1vw, 14px);
	color: black;
	cursor: pointer;
}

.keepSignedInInput input {
		
}

.forgotPasswordContainer {
	text-align: right;
	font-size: max(1vw, 14px);
	color: black;
}

.forgotPasswordContainer a {
	cursor: pointer;
}

.loginButton {
	width: 100%;
	padding: max(0.5vw, 10px);
	height: max(7vh, 50px);
	border-radius: 10px;
	color: white;
	font-size: max(1.2vw, 18px);
	font-weight: 500;
	background-color: #3D5DAA;
	border: none;
	outline: none;
}


@media only screen and (max-width: 912px) {
	.loginFormInputContainer {
		width: 100%;
	}
}

@media only screen and (min-width: 1919px) {
	.keepSignedInInput input {
		width: 0.8vw !important;
		height: 0.8vw !important;
	}
}