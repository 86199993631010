.AddShiftModalArea {
	position: relative;
	display: block;
	/* max-width: 500px; */
	width: min(100%, 800px );
	/* width: 100%; */
	height: auto;
	background-color: white;
	margin: auto;
	border-radius: 10px;
	padding: 2vw;
}

.AddShiftModalArea h1 {
	font-size: max(2vw, 32px);
	color: #3D5DAA;
	font-weight: 500;
}

.addShiftFormInputContainer {
	/* background-color: red; */
	padding-top: 1vh;
	padding-bottom: 1vh;
}

.addShiftFormInputContainer p {
	color: #666666;
	font-size: max(1vw, 16px);
	/* background-color: red; */
	margin-bottom: 1vh;
}

.AddShiftModalArea .formTextInput {
	width: 100%;
	height: min(6vh, 42px );
	border-radius: 5px;
	border: none;
	background-color: #ECEFF7;
	outline: none;
	color: #666666;
	font-size: max(1vw, 16px);
	padding-left: max(1vw, 16px);
	/* background-color: green; */
}

.addShiftFormActiveDaysCheckboxesContainer {
	display: flex;
	flex-direction: row;
	min-height: 6vh;
	padding-top: 1vh;
	padding-bottom: 1vh;
}

.checkboxContainer {
	/* display: flex; */
	width: calc( 100% / 7 );
	padding-right: max(1vw, 16px);
	cursor: pointer;
}

.checkboxContainer label {
	font-size: max(16px, 1vw);
	color: #666666;
	margin-left: max(0.5vw, 8px);
	cursor: pointer;
}

.checkboxInput{
	cursor: pointer;
}

.updateShiftFormActionContainer .cancelButton {
	min-width: max( 10vw, 120px );
	margin-right: max( 1vw, 16px );
}

.updateShiftFormActionContainer .updateButton {
	min-width: max( 10vw, 120px );
	margin-right: max( 1vw, 16px );
}

.updateShiftFormActionContainer .deleteButton {
	min-width: max( 10vw, 120px );
}

@media (max-width: 767px) {
  .addShiftFormActiveDaysCheckboxesContainer {
    flex-direction: column;
  }
	.checkboxContainer {
		width: 100%;
	}
}

@media only screen and (min-width: 1919px) {
	.AddShiftModalArea {
		width: 60vw;
		height: auto;
		max-width: none;
		min-width: none;
		max-height: none;
		min-height: none;
	}
	.addShiftForm input {
		height: 6vh;
	}

	.checkboxContainer {
		/* display: flex; */
		width: calc( 100% / 7 );
		padding-right: max(1vw, 16px);
		/* background-color: red; */
	}
	
	.checkboxContainer label {
		font-size: max(16px, 1vw);
		color: #666666;
		margin-left: max(0.5vw, 8px);
		cursor: pointer;
		/* background-color: green; */
	}

	.checkboxInput{
		height: 1vw !important;
		width: 1vw !important;
	}
}