.HomeContainer {
	height: 100vh;
	width: 75vw;
	padding-left: 1vw;
}

.HomeContainer .container {
	width: 75vw;
	/* background-color: green; */
	width: 100%;
	max-width: 100% !important;
}

.HomeContainer .col-6 {
	
}

.employeesClockInList {
	/* background-color: red; */
	min-height: 88vh;
	height: 88vh;
	max-height: 88vh;
	width: 100%;
	position: relative;
	overflow: scroll;
	padding-bottom: 2vh;
}

.tabsContainer {
	width: 100%;
	height: 6vh;
	border-bottom: solid 1px #9D9D9D;
	display: flex;
}

.tabsContainer .tab {
	/* background-color: yellow; */
	line-height: 6vh;
	/* padding-left: 1vw; */
	padding-right: 1vw;
	font-size: max(1.2vw, 16px);
	cursor: pointer;
}

.tabsContainer .tab p {
	font-weight: 400;
	
	color: #666666;
}

.tabsContainer .activeTab p {
	font-weight: 500;
	color: black;
}

.downloadReportContainer {
	width: 100%;
	padding-bottom: 5vh;
	padding-top: 2vh;
}

.downloadReportForm {
	display: flex;
	width: 100%;
	/* justify-content:space-between; */
	justify-content: flex-end;
}

.monthYearPicker {
}

.monthYearPicker input {
	border-radius: 5px;
	border: solid 2px #555555;
	padding-left: 1vw;
	text-transform: capitalize;
	color: #555555;
	height: 5vh;
	font-size: max( 1.2vw, 18px );
}

.downloadReportForm button {
	border-radius: 5px;
	background-color: #3D5DAA;
	color: white;
	font-size: max( 1vw, 14px );
	border: none;
	outline: none;
	height: 5vh;
	padding-left: 1vw;
	padding-right: 1vw;
	margin-left: 1vw;
}

.chartContainer {
	/* background-color: yellow; */
	min-height: 82vh;
	font-size: max(1vw, 16px);
}

.attendancePieChart {
	height: 50vh;
}

@media only screen and (max-width: 912px) {
	#home-page .employeesClockInList {
		/* background-color: red; */
		max-height: 88vh;
		height: auto;
		min-height: initial;
		width: 100%;
		position: relative;
		overflow: scroll;
		padding-bottom: 2vh;
	}

	#home-page .cardContainer {
		margin-top: 2vh;
	}

	#home-page .monthYearPicker input {
		font-size: max( 1vw, 16px );
		width: 90%;
	}
	
	#home-page .downloadReportForm button {
		font-size: max( 0.8vw, 12px );
		margin-left: 0.5vw;
	}
}

@media only screen and (min-width: 1919px) {
	#home-page .employeesClockInList {
		/* background-color: red; */
		min-height: 88vh;
		height: 88vh;
		width: 100%;
		position: relative;
		overflow: scroll;
		padding-bottom: 2vh;
	}

	#home-page .cardContainer {
		margin-top: 2vh;
	}

	#home-page .monthYearPicker input {
		font-size: max( 1vw, 16px );
		width: 100%;
	}

	#home-page .downloadReportForm {
		display: flex;
		width: 100%;
		justify-content: flex-end;
	}

	#home-page .downloadReportForm button {
		font-size: max( 0.8vw, 12px );
		margin-left: 0.5vw;
	}
}