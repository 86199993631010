.addShiftButtonContainer {
	height: 6vh;
	margin-bottom: 1vh;
}

.addShiftButton {
	height: 100%;
	padding-left: max(2vw, 28px);
	padding-right: max(2vw, 28px);
	border-radius: 5px;
	background-color: #3D5DAA;
	color: white;
	font-size: max(1vw, 16px);
	font-weight: 500;
	outline: none;
	border: none;
}

.shiftTableContainer {
	min-height: 80vh;
}
.shiftsTable {
	width: 100%;
	height: 100%;
	min-height: 60vh;
	overflow-x: scroll;
}

.shiftsTable table {
	width: 100%;
	white-space: nowrap;
	overflow-x: auto;
}

.shiftsTable table th {
	width: 200px;
	font-size: max(1vw, 18px);
	font-weight: 500;
	color: #666666;
	border-bottom: solid 1px #666666;
	min-height: 4vh;
	padding-right: 5vw;
}

.shiftsTable table td {
	width: 400px;
	font-size: max(0.9vw, 16px);
	font-weight: 400;
	color: #666666;
	border-bottom: solid 1px #666666;
	padding-top: 1vh;
	padding-bottom: 1vh;
	padding-right: 2vw;
}