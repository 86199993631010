.employeesTableContainer {
	height: 100vh;
	max-height: 100vh;
	min-height: 100vh;
	overflow: scroll;
	/* background-color: red; */
}

.employeesTableContainer table {
	width: 100%;
}

.employeesTableContainer table tr {
	border-bottom: solid 1px #9D9D9D;
}

.employeesTableContainer table .activeRow {
	background-color: #EAEDF6;
}

.employeesTableContainer th {
	font-size: max(1.1vw, 12px );
	font-weight: 500;
	color: #666666;
	padding-top: 1vh;
	padding-bottom: 1vh;
	padding-left: 0.5vw;
	padding-right: 0.5vw;
}

.employeesTableContainer td {
	color: #555555;
	font-size: max(1.2vw, 14px);
	font-weight: 400;
	padding-top: 1vh;
	padding-bottom: 1vh;
	padding-left: 0.5vw;
	padding-right: 0.5vw;
	cursor: pointer;
}

#employeeTableContainer .employeeStatusButton {
	border-radius: 5px;
	height: 6vh;
	width: 100%;
	border: none;
	outline: none;
	font-size: max(1.2vw, 12px);
	font-weight: 500;
}

#employeeTableContainer .clockedInStatus {
	
}

#employeeTableContainer .clockedOutStatus {
	font-size: max( 0.7vw, 12px );
}

#employeeTableContainer .onLeaveStatus {
	font-size: max(1vw, 10px);
}

#employeeTableContainer .unknownStatus {
	font-size: max(1vw, 10px);
}

.listActionContainer {
	width: 100%;
	position: absolute;
	bottom: 0.5vh;
	right: 0.5vw;
}

#employeeTableContainer .listActionContainer .listActionButton {
	margin-left: 1vw;
	padding-left: max( 1vw, 16px );
	padding-right: max( 1vw, 16px );
	height: 6vh;
	border-radius: 5px;
	border: none;
	outline: none;
}

#employeeTableContainer .listActionContainer .prevButton {
	background-color: #FFD600;
	color: #525252;
	font-weight: 500;
	font-size: max( 14px, 1.2vw);
}

#employeeTableContainer .listActionContainer .nextButton {
	background-color: #3D5DAA;
	color: white;
	font-weight: 500;
	font-size: max( 14px, 1.2vw);
}


@media only screen and (max-width: 912px) {
	.employeesTableContainer {
		height: auto;
		min-height: initial;
		max-height: 100vh;
		/* background-color: red; */
	}
}

@media only screen and (min-width: 1919px) {
	.employeesTableContainer {
		height: 100vh;
		/* background-color: red; */
	}
}