#calendar-page .calendarContainer {
	/* background-color: red; */
	width: 100%;
	font-size: max(1.1vw, 16px);
	height: 50vh;
}

#calendar-page .dateText {
	font-size: max(13px, 1vw);
	font-weight: 400;
	line-height: min(40px, 6.5vh);
	color: #666666;
}

.calendarContainer .eventDay {
	background-color: #FFD600;
	color: black;
}

#calendar-page .addHolidayButton {
	border-radius: 5px;
	border: none;
	background-color: #3D5DAA;
	color: white;
	padding-left: max(2vw, 28px);
	padding-right: max(2vw, 27px);
	height: min(40px, 6.5vh);
	font-size: max(16px, 1vw);
}

#calendar-page .holidayList {
	height: 80vh;
}

#calendar-page .eventCard {
	background-color: #ECEFF7;
	border-radius: 5px;
	padding: max(1vw, 12px);
	position: relative;
	margin-bottom: max(2vh, 12px);
}

#calendar-page .eventCard p {
	font-size: max(1vw, 13px);
	margin: 0;
}

#calendar-page .eventCard img {
	position: absolute;
	right: max(0.5vw, 6px);
	top: max(0.5vw, 6px);
	height: max(30px, 4vh);
	cursor: pointer;
}

@media only screen and (min-width: 1919px) {
	#calendar-page .addHolidayButton {
		height: 4vh;
		margin-bottom: 2vh;
	}

	#calendar-page .calendarContainer {
		font-size: 1.5vw;
	}
}