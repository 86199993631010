#timesheet-page .employeeListContainer {
	/* background-color: red; */
}

@media only screen and (max-width: 912px) {
	.EmployeeTimestampTableContainerCard {
		margin-top: 2vh;
	}
}

@media only screen and (min-width: 1919px) {

}