.devicesTableContainer {
	/* background-color: red; */
	width: 100%;
	padding-left: max(2vw, 16px);
	padding-right: max(2vw, 16px);
}

.devicesTableContainer .deviceRow {
	/* background-color: green; */
	/* border-top: solid 1px grey; */
	border-bottom: solid 1px #FFD600;
	cursor: pointer;
	
}

.devicesTableContainer .deviceRow .deviceName {
	font-weight: 500;
	font-size: max(1vw, 16px);
	color: #666666;
	padding: max(0.5vw, 8px);
	margin: 0;
}

.devicesTableContainer .deviceRow .deviceId {
	font-weight: 400;
	color: #5C5C5C;
	font-size: max(1vw, 16px);
	padding: max(0.5vw, 8px);
	margin: 0;
}

.registerDeviceButton {
	font-size: max(1vw, 16px);
	border-radius: 5px;
	height: max(3vw, 50px);
	padding-left: max(1vw, 16px);
	padding-right: max(1vw, 16px);
	background-color: #3D5DAA;
	border: none;
	outline: none;
	color: white;
}

#devicesPage .cardContainer {
	min-height: 90vh;
}