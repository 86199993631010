.navbarContainer {
	/* background-color: red; */
	min-height: 100vh;
	position: fixed;
	width: 25vw;
	/* overflow-y: scroll;
	overflow-x: hidden; */
	border-right: solid 1px #CACACA;
	z-index: 4;
}

.DesktopNavbarArea {
	position: fixed;
	width: 25vw;
	height: 100%;
	background-color: white;
	overflow-y: scroll;
	overflow-x: hidden;
	z-index: 0;
	/* background-color: green; */
	display: block;
}

.navbarLogoContainer {
	padding-top: 2vh;
	padding-bottom: 2vh;
	padding-left: 2vw;
	height: 12vh;
}

.menuLogoContainer {
	height: 8vh;
}

.menuLogoContainer img {
	height: 100%;
}

.collapseButtonArea {
	width: 100%;
	height: 8vh;
	cursor: pointer;
}

.collapseButtonArea img {
	height: 100%;
	float: right;
	padding: 10%;
}

.collapsedNavbarContainer {
	background-color: white;
	min-height: 100vh;
	position: fixed;
	width: 6vw;
	border-right: solid 1px #CACACA;
	z-index: 4;
}

.DesktopCollapseNavbarArea {
	height: 100vh;
	position: fixed;
	width: 6vw;
	padding-left: 1vw;
	padding-right: 1vw;
	padding-top: 2vh;
}

.DesktopCollapseNavbarArea .menuLogoContainer {
	/* background-color: green; */
	position: relative;
}

.DesktopCollapseNavbarArea .desktopCollapseNavbarMenuLogo {
	/* background-color: green; */
	width: 100%;
}

.DesktopCollapseNavbarArea .menuExpandButtonArea {
	position: absolute;
	background-color: #3D5DAA;
	border-radius: 50%;
	cursor: pointer;
	right: -1vw;
	z-index: 3;
	top: 50%;
	height: max(4vh, 24px);
	width: max(4vh, 24px);
	border: none;
	outline: none;
	border: solid 1px #666666;

}

.DesktopCollapseNavbarArea .menuExpandButtonArea img {
	width: 100%;
	height: 100%;
}

.mobileNavbarContainer {
	display: none;
}

.MobileNavbarArea {
	display: none;
}

.mobileNavbarButtonContainer {
	display: none !important;
}

.navbarShow {
	display: block;
}

.navbarHide {
	display: none;
}

@media only screen and (max-width: 912px) {
	.navbarContainer {
		display: none;
	}

	.DesktopNavbarArea {
		display: none;
	}

	.collapsedNavbarContainer {
		display: none;
	}

	.DesktopCollapseNavbarArea {
		display: none;
	}

	.mobileNavbarButtonContainer {
		display: block !important;
		position: fixed;
		background-color: red;
		width: 100vw;
		/* top: max( 2vh, 28px ); */
		z-index: 1;		
	}

	.mobileNavbarButton {
		height: max( 5.5vh, 48px );
		width: max( 5.5vh, 48px );
		margin-right: max( 2vw, 28px );
		border-radius: 5px;
		background-color: #FFD600;
		background-color: white;
		padding: 6px;
		cursor: pointer;
		box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
		position: absolute;
		right: -2vh;
		top: 1vh;
	}

	.mobileNavbarButton img {
		width: 100%;
		height: 100%;
	}
	
  

	.mobileNavbarContainer {
		display: block;
		height: 100vh;
		width: 100vw;
		z-index: 5;
	}

	.MobileNavbarArea {
		display: block;
		width: 100%;
		position: fixed;
		z-index: 4;
		background-color: #ECEFF7;
		height: 100vh;
		left: 0;
		top: 0;
	}

	.mobileMenuLogoContainer {
		padding: 1vh;
	}

	.mobileMenuLogoContainer .mobileMenuLogo {
		height: 8vh;
		width: auto;
	}

	.mobileMenuLogoContainer .closeMobileMenuButton {
		height: 6vh;
		width: 6vh;
	}

	.navbarShow {
		display: block;
	}
	
	.navbarHide {
		display: none;
	}
}