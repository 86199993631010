#employeeDetailsModalContainer  {
	display: none;
}

#employeeDetailsModalContainer .employeeDetailsContainer {
	background-color: white;
	padding: 3vw;
	position: relative;
	border-radius: 1vw;
}

#employeeDetailsModalContainer .cancelButtonContainer {
	position: absolute;
	z-index: 7;
	left: 3vw;
	top: 3vw;
}

#employeeDetailsModalContainer .cancelButtonContainer button {
	background-color: #FFD600;
	height: 5vh;
	border-radius: 5px;
	width: 25vw;
	border: none;
	color: 'grey';
}


#employeeDetailsModalContainer .editEmployeeRow img {
	height: max(2vw, 32px);
	cursor: pointer;
}

#employeeDetailsModalContainer .profilePhotoContainer {
	/* background-color: red;	 */
}

#employeeDetailsModalContainer .profilePhotoContainer img {
	height: max(24vh, 200px);
	width: max(24vh, 200px);
	border-radius: 50%;
}

#employeeDetailsModalContainer .employeeDetailsTextArea {
	padding-left: 1vw;
	width: 100%;
	padding-top: 3vh;
}

#employeeDetailsModalContainer .employeeDetailsTextArea p {
	font-size: max(1vw, 16px);
	font-weight: 400;
	color: #666666;
	width: 100%;
	overflow-wrap: break-word;
}

#employeeDetailsModalContainer .bottomCancelButtonContainer {
	align-items: center;
	width: 100%;
	text-align: center;
}

#employeeDetailsModalContainer .bottomCancelButtonContainer button {
	background-color: #FFD600;
	height: 5vh;
	border-radius: 5px;
	width: 25vw;
	border: none;
	color: 'grey';
}

#employeeDetailsModalContainer .employeeDeviceActionArea {
	/* padding-top: 1vw; */
	padding-bottom: 3vw;
}

#employeeDetailsModalContainer .userSavedInDevice {
	color: #6FB54E;
	font-size: max(0.8vw, 12px);
	padding-right: 1vw;
	line-height: max(2vw, 32px);
}

#employeeDetailsModalContainer .saveUserInDeviceButton {
	border: none;
	outline: none;
	background-color: #FFD600;
	color: #5C5C5C;
	font-size: max(0.8vw, 12px);
	padding-left: 1em;
	padding-right: 1em;
	border-radius: 5px;
	height: max(2vw, 32px);
	margin-right: 1vw;
}

#employeeDetailsModalContainer .deleteEmployeeButton {
	border: none;
	outline: none;
	background-color: #F34B4B;
	color: white;
	font-size: max(0.8vw, 12px);
	padding-left: 1em;
	padding-right: 1em;
	border-radius: 5px;
	height: max(2vw, 32px);
}

@media only screen and (max-width: 768px) {
	#employeeDetailsModalContainer  {
	}
}

@media only screen and (max-width: 912px) {
	#employeeDetailsModalContainer  {
		display: block;
		position: absolute;
		background-color: rgba(0,0,0,0.5);
		top: 0;
		left: 0;
		z-index: 7;
		width: 100%;
		left: 0;
		padding: 5vw;
	}
}

@media only screen and (min-width: 1919px) {

}