.logoContainer {
	width: 100%;
	text-align: center;
	height: 100vh;
}

.logoContainer img {
	padding: max(0.6vw, 10px);
	width: max(15vw, 250px);
	height: 100%;
	justify-content: center;
	position: fixed;
	left: max( 25% - 7.5vw, 25% - 250px );
}


.companyRegisterFormInputContainer {
	margin-top: max(4vh, 40px);
	height: 100%;
	width: 100%;
	text-align: center;
	padding: max(1vw, 16px);
}

.companyRegisterFormInputContainer h1 {
	font-size: max(1.5vw, 24px);
	color: #3D5DAA;
	margin-bottom: max(1vw, 16px);
}

.companyRegisterFormInput {
	border: none;
	padding-top: max(0.5vw, 8px);
	padding-bottom: max(0.5vw, 8px);
}

.companyRegisterFormInput input {
	width: 100%;
	padding-left: max(1vw, 16px);
	height: max(7vh, 50px);
	border: none;
	outline: none;
	border-radius: 10px;
	font-size: max(1vw, 16px);
	background-color: rgb(236, 239, 247);
}

.submitButton {
	width: 100%;
	padding: max(0.5vw, 10px);
	height: max(7vh, 50px);
	border-radius: 10px;
	color: white;
	font-size: max(1.2vw, 18px);
	font-weight: 500;
	background-color: #3D5DAA;
	border: none;
	outline: none;
}

.apiResponseText {
	color: #F34B4B;
}

@media only screen and (max-width: 768px) {
	.logoContainer {
		height: 20vh;
	}
	.logoContainer img {
		position: relative;
		left: 0;
	}
	
}

@media only screen and (min-width: 1919px) {
	
}


