.addHolidayModalArea {
	width: 100vw;
	position: relative;
	display: block;
	max-width: 500px;
	max-height: 300px;
	background-color: white;
	margin: auto;
	border-radius: 10px;
	padding: 2vw;
}

.addHolidayModalArea h1 {
	font-size: max(2vw, 32px);
	color: #3D5DAA;
	font-weight: 500;
}

.addHolidayForm p {
	color: #666666;
	font-size: max(1vw, 16px);
}

.addHolidayForm input {
	width: 100%;
	height: min(6vh, 42px );
	border-radius: 5px;
	border: none;
	background-color: #ECEFF7;
	outline: none;
	color: #666666;
	font-size: max(1vw, 16px);
	padding-left: max(1vw, 16px);
}

.AddHolidayFormActionContainer .cancelButton {
	min-width: max( 10vw, 42px );
	margin-right: max( 1vw, 16px );
}

.AddHolidayFormActionContainer .saveButton {
	min-width: max( 10vw, 42px );
}

@media only screen and (min-width: 1919px) {
	.addHolidayModalArea {
		width: 30vw;
		height: 30vh;
		max-width: none;
		min-width: none;
		max-height: none;
		min-height: none;
	}
	.addHolidayForm input {
		height: 6vh;
	}
}