.navbarOptionContainer {
	height: 10vh;
	padding-left:2vw;
	padding-top: 1vh;
	padding-bottom: 1vh;
	border-top: solid #CACACA;
	border-width: 0.1px;
	/* border-bottom: solid 1px #CACACA; */
	cursor: pointer;
}

.navbarActive {
	background-color: #3D5DAA;
}

.navbarActive .navbarOptionTitle h1 {
	color: white !important;
}

.navbarOptionTitle {
	height: 8vh;
	display: flex;
}

.navbarOptionTitle img {
	height: 100%;
	padding: min(6%, 3vw);
	/* background-color: red; */
}

.navbarOptionTitle h1 {
	height: 100%;
	line-height: 8vh;
	font-size: max(1vw, 18px);
	font-weight: 400;
	color: #646464;
}

.expandButtonArea {
	height: 8vh;
	width: 100%;
}

.expandButtonArea svg {
	height: 100%;
	width: auto;
	padding: 20%;
	float: right;
}

.expandButtonArea svg path {
	stroke: #3D5DAA;
}

.navbarActive .expandButtonArea svg path {
	stroke: white;
}

.collapsedNavbarOptionContainer {
	/* background-color: red; */
	border-top: 1px solid #CACACA;
	cursor: pointer;
}

.collapsedNavbarOptionContainer img {
	width: 100%;
	padding: 25%;
}