.newRequestCard {
	background-color: #ECEFF7;
	margin-top: min( 40px, 1vw );
	/* height: 100%; */
}

.newRequestCard p {
	font-size: max(1vw, 16px);
	font-weight: 400;
	color: #3C3C3C;
	margin: 0;
}

.newRequestCard .requestEmployeeName {
	font-weight: 500;
}

.newRequestCard .requestFileContainer {
	margin-top: min(1vh, 8px);
}

.newRequestCard .requestFileContainer img {
	height: max( 1.5vw, 20px ) ;
	margin-right: max(0.3vw, 4px);
}

.newRequestCard .requestActionContainer {
	margin-top: min(2vh, 8px);
}

.newRequestCard .requestActionContainer button {
	padding-left: 2vw;
	padding-right: 2vw;
	margin-right: max(1vw, 6px);
	font-size: max(1vw, 16px);
	border-radius: 5px;
	height: max( 6vh, 40px );
	background: none;
	outline: none;
	border: none;
}

.newRequestCard .requestActionContainer .approveButton {
	background-color: #3D5DAA;
	color: white;
}

.newRequestCard .requestActionContainer .rejectButton {
	background-color: #FFD600;
	color: #666666;
}