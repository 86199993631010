.updateDeviceModalArea {
	width: 100vw;
	position: relative;
	display: block;
	/* max-width: 500px; */
	width: min(100%, 700px );
	/* width: 100%; */
	height: auto;
	background-color: white;
	margin: auto;
	border-radius: 10px;
	padding: 2vw;
}

.updateDeviceModalArea h1 {
	font-size: max(2vw, 32px);
	color: #3D5DAA;
	font-weight: 500;
}

.updateDeviceFormInputContainer {
	/* background-color: red; */
	padding-top: 1vh;
	padding-bottom: 1.5vh;
}

.updateDeviceFormInputContainer p {
	color: #666666;
	font-size: max(1vw, 16px);
	/* background-color: red; */
	margin-bottom: 0.5vh;
}

.updateDeviceModalArea .formTextInput {
	width: 100%;
	height: min(6vh, 42px );
	border-radius: 5px;
	border: none;
	background-color: #ECEFF7;
	outline: none;
	color: #666666;
	font-size: max(1vw, 16px);
	padding-left: max(1vw, 16px);
	height: 5vh;
	/* background-color: green; */
}

.updateDeviceFormActiveDaysCheckboxesContainer {
	display: flex;
	flex-direction: row;
	min-height: 6vh;
	padding-top: 1vh;
	padding-bottom: 1vh;
}

.checkboxContainer {
	/* display: flex; */
	width: calc( 100% / 7 );
	padding-right: max(1vw, 16px);
	cursor: pointer;
}

.checkboxContainer label {
	font-size: max(16px, 1vw);
	color: #666666;
	margin-left: max(0.5vw, 8px);
	cursor: pointer;
}

.checkboxInput{
	cursor: pointer;
}

.updateDeviceFormActionContainer .cancelButton {
	min-width: max( 10vw, 120px );
	margin-right: max( 1vw, 16px );
}

.updateDeviceFormActionContainer .saveButton {
	min-width: max( 10vw, 120px );
}

@media (max-width: 767px) {
  .updateDeviceFormActiveDaysCheckboxesContainer {
    flex-direction: column;
  }
	.checkboxContainer {
		width: 100%;
	}
}

@media only screen and (min-width: 1919px) {
	.updateDeviceModalArea {
		width: 60vw;
		height: auto;
		max-width: none;
		min-width: none;
		max-height: none;
		min-height: none;
	}
	.updateDeviceForm input {
		height: 6vh;
	}
}