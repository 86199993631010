.EmployeeTimestampTableContainer {
	width: 100%;
	height: 100vh;
}

.EmployeeTimestampTableContainer .dateSelectContainer {
	height: max( 5vh, 50px );
	width: 100%;
	/* background-color: red; */
}

.EmployeeTimestampTableContainer .dateSelectContainer .monthYearPicker {
	height: 100%;
}

.EmployeeTimestampTableContainer .dateSelectContainer .monthYearPicker input {
	width: 100%;
	height: max( 5vh, 50px );
	border: none;
	background-color: #ECEFF7;
	border-radius: 5px;
	padding-left: 1vw;
	padding-right: 1vw;
	color: #666666;
	font-size: max(1vw, 16px);
}

.EmployeeTimestampTableContainer .clockedInhoursText {
	color: #666666;
	font-size: max(0.8vw, 14px);
	height: 100%;
	word-wrap: normal;
}

.EmployeeTimestampTableContainer .addAttendanceContainer {
	width: 100%;
}

.EmployeeTimestampTableContainer .addAttendanceContainer button {
	/* width: max(8vw, 120px); */
	padding-left: 1vw;
	padding-right: 1vw;
	font-size: max(0.8vw, 14px);
	font-weight: 500;
	border: none;
	border-radius: max(0.4em, 8px);
	height: max( 5vh, 50px );
	background-color: #3D5DAA;
	color: white;
}

.EmployeeTimestampTableContainer .addAttendanceModal {
	/* background-color: red; */
	padding: 1em;
	width: 100%;
	border: solid 2px #6C6C6C;
	margin-top: 1em;
	border-radius: 5px;
	/* display: flex; */
	/* justify-content: space-evenly; */
}

.EmployeeTimestampTableContainer .addAttendanceModal .inputBox {
	width: 100%;
}

.EmployeeTimestampTableContainer .addAttendanceModal .inputBox .attendanceTypeInput {
	width: 40%;
	border: none;
	border-radius: 5px;
	color: #5C5C5C;
	outline: none;
	background-color: #ECEFF7;
	padding-left: 1em;
	padding-right: 1em;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}

.EmployeeTimestampTableContainer .addAttendanceModal .inputBox .attendanceTimeInput {
	width: 40%;
	border: none;
	border-radius: 5px;
	color: #5C5C5C;
	outline: none;
	background-color: #ECEFF7;
	padding-left: 1em;
	padding-right: 1em;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}

.EmployeeTimestampTableContainer .addAttendanceModal .actionBox {
	padding: 1em;
	padding-bottom: 0;
}

.EmployeeTimestampTableContainer .addAttendanceModal .actionBox button {
	width: 45%;
}

.timestampTableColumn {

}

.timestampTableContainer {
	overflow-y: scroll;
	height: 90vh;
	display: block;
}

.timestampTable {
	width: 100%;
	table-layout: fixed;
	/* height: 90vh; */
}

.timestampTableBody {
	/* background-color: green; */
}

.timestampTableContainer table tr {
	border-bottom: solid 1px #9D9D9D;
	height: min(5vh, 50px);
}

.timestampTableContainer table th {
	width: 100%;
	font-size: max(0.8vw, 14px);
	font-weight: 400;
	color: #666666;
}

.timestampTableContainer table td {
	color: #1F1F1F;
	font-size: max(1.1vw, 16px);
	font-weight: 400;
}

.timesheetActivityText {

}

.activityTimestampText {
	display: flex;
	width: 100%;
}

.activityTimestampText p {
	/* background-color: red; */
	width: 100%;
}

.activityTimestampText button {
	font-size: max(0.6vw, 12px);
	color: grey;
	font-weight: 500;
	background-color:  #FFD600;
	/* padding-left: 5px; */
	/* padding-right: 5px; */
	padding: 5px;
	margin-left: max(0.6vw, 12px);
	border-radius: 2px;
	cursor: pointer;
	height: max(3em, 24px);
	border: none;
} 

.timestampMapButton {
	border: none;
	background-color: grey;
	outline: none;
	border-radius: 5px;
	color: white;
	font-size: max(0.6vw, 12px);
	margin-left: 0.5vw;
}

@media only screen and (max-width: 912px) {
	.EmployeeTimestampTableContainer {
		
	}
}

@media only screen and (min-width: 1919px) {

}