#reportsPage .cardContainer {
	min-height: 90vh;
}

#reportsPage .reportContainer {
	/* background-color: green; */
	margin-top: 2vh;
	padding-top: 1vw;
	padding-bottom: 5vh;
	border-bottom: solid 1px #9EAED4;
}

#reportsPage .reportContainer .reportHeading {
	font-size: max(1.5vw, 22px);
	color: #666666;
}

#reportsPage .reportInputContainer {
	/* background-color: red; */
	width: 100%;
	margin-top: 2vh;
	/* height: 10vh; */
}

#reportsPage .reportInputContainer .monthYearPicker input {
	width: 100%;
	height: 5vh;
	padding-left: 1vw;
	padding-right: 1vw;
	border-radius: 5px;
	border: solid 2px #555555;
}

#reportsPage .reportInputContainer .selectReportEmployee {
	width: 100%;
	height: 5vh;
	padding-left: 1vw;
	padding-right: 1vw;
	border-radius: 5px;
	border: solid 2px #555555;
	font-size: max(1vw, 16px);
}

#reportsPage .reportInputContainer .downloadButton {
	background-color: #3D5DAA;
	border-radius: max(5px, 0.2vw);
	border: none;
	padding-left: 1.5vw;
	padding-right: 1.5vw;
	height: 5vh;
	color: white;
	font-size: max(1vw, 16px);
}