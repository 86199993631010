/* #requests-page .requestCard {
	background-color: #ECEFF7;
	margin-top: min( 40px, 1vw );
} */

#requests-page .requestCard .requestEmployeeName {
	font-weight: 500;
}

#requests-page .requestCard .requestFileContainer {
	margin-top: min(1vh, 8px);
}

#requests-page .requestCard .requestFileContainer img {
	height: max( 1.5vw, 20px ) ;
	margin-right: max(0.3vw, 4px);
}

#requests-page .requestCard .requestActionContainer {
	margin-top: min(2vh, 8px);
}