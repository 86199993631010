#EmployeesOnLeaveListContainer .listContainer {
	/* background-color: grey; */
	/* height: 100px; */
	width: 100%;
	padding-top: 2vh;
	padding-left: 0.5vw;
}

#EmployeesOnLeaveListContainer .listContainer table {
	/* background-color: red; */
	width: 100%;
}

#EmployeesOnLeaveListContainer .listContainer tr {
	width: 100%;
	border-bottom: solid 1px #9D9D9D;
	height: 6vh;
}

#EmployeesOnLeaveListContainer .listContainer th {
	width: 33.3%;
	color: #666666;
	font-size: max(12px, 1.1vw);
	font-weight: 500;
}

#EmployeesOnLeaveListContainer .listContainer td {
	width: 33.3%;
	color: #555555;
	font-size: max(14px, 1.2vw);
	font-weight: 400;
}

#EmployeesOnLeaveListContainer .listContainer .leaveDates {
	font-size: max(12px, 1vw);
}

#EmployeesOnLeaveListContainer .listActionContainer {
	width: 100%;
	position: absolute;
	bottom: 0.5vh;
	right: 0.5vw;
}

#EmployeesOnLeaveListContainer .listActionContainer .listActionButton {
	margin-left: 1vw;
	padding-left: 1vw;
	padding-right: 1vw;
	height: 6vh;
	border-radius: 5px;
	border: none;
	outline: none;
}

#EmployeesOnLeaveListContainer .listActionContainer .prevButton {
	background-color: #FFD600;
	color: #525252;
	font-weight: 500;
	font-size: max( 14px, 1.2vw);
}

#EmployeesOnLeaveListContainer .listActionContainer .nextButton {
	background-color: #3D5DAA;
	color: white;
	font-weight: 500;
	font-size: max( 14px, 1.2vw);
}