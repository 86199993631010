#warningModal .warningModalBackground {
	position: fixed;
	z-index: 8;
	top: 0;
	left: 0;
	background-color: rgba(0,0,0,0.5);
	height: 100%;
	width: 100%;
	align-items: center;
}

.warningModal {
	background-color: white;
	border-radius: 1em;
	/* height: 15em; */
	width: 30em;
	max-width: 80%;
	max-height: 50%;
}

.modalHeadingArea {
	padding-top: max(1em, 16px);
}

.modalHeading {
	width: 100%;
	font-size: max( 1.5em, 22px );
	text-align: center;
	color: #F34B4B;
}

.modalBodyArea {
	padding: max(1em, 16px);
}

.modalMessage {
	font-size: max(1.2em, 18px);
	color: #5C5C5C;
}

.modalActionArea {
	padding: max(1em, 16px);
}

.modalActionArea button {
	width: 100%;
	max-height: max(2em, 28px);
}

.closeModalButton {
	position: absolute;
	top: 1em;
	right: 1em;	
}

#warningModal .close-popup-button {
  position: absolute;
  right: 1.063vw;
  top: 1.063vw;
  cursor: pointer;
}

#warningModal .close-popup-button img {
  width: 1.5vw;
  height: 1.5vw;
}

#warningModal .welcome-popup-card h1 {
  margin-top: 2.25vw;
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 2vw;
  line-height: 3vw;
  /* color: #7386AA; */
  background: linear-gradient(271.36deg, #ff6f77 0%, #feac99 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

#warningModal .welcome-popup-card h2 {
  margin-inline: 2vw;
  margin-top: 1vw;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 1.875vw;
  text-align: center;
  color: #7386aa;
}

#warningModal .popup-button-area {
  height: 3.5vw;
  margin-top: 1.25vw;
  padding-inline: 2.5vw;
}

#warningModal .popup-left-button-area {
  width: 50%;
  padding-right: 0.75vw;
  text-align: center;
}

#warningModal .popup-right-button-area {
  width: 50%;
  padding-left: 0.75vw;
  text-align: center;
}

#warningModal .cancel-button {
  border: none;
  outline: none;
  mix-blend-mode: normal;
  background-size: 100% 100%;
  height: 100%;
  width: 9.938vw;
  font-style: normal;
  font-weight: 700;
  font-size: 1vw;
  color: #7386aa;
}

#warningModal .cashier-button {
  border: none;
  outline: none;
  mix-blend-mode: normal;
  background-size: 100% 100%;
  width: 9.563vw;
  height: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 1vw;
  color: #fbfcff;
}

.welcome-popup-avatar-container img {
  width: 7.375vw;
  height: 7.375vw;
}
#warningModal .welcome-popup-card h3 {
  text-align: center;
  color: #7386aa;
  font-weight: 700;
  font-size: 1.5vw;
  line-height: 2.25vw;
  margin-top: 0.5vw;
}
#warningModal .welcome-popup-card h4 {
  text-align: center;
  font-weight: 700;
  font-size: 4vw;
  line-height: 6vw;
  color: #8ac75b;
}
