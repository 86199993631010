body {
	width: 100% !important;
	height: 100% !important;
	background-color: rgb(236, 228, 250);
	font-family: 'Roboto', sans-serif;
	padding: 0;
}

.inputContainer {
	border: none;
	padding-top: 10px;
	padding-bottom: 10px;
}

.inputContainer input {
	width: 100%;
	padding-left: 20px;
	height: 60px;
	border: none;
	outline: none;
	border-radius: 10px;
	background-color: rgb(236, 239, 247);
}

.cardContainer {
	background-color: white;
	border-radius: 10px;
	border: none;
	padding: 20px;
}

.mainContainer {
	z-index: 0;
	padding-left: 25vw;
}

.collapsedMenuMainContainer {
	padding-left: 10vw;
}

.mainContentContainer {
	height: 100vh;
	width: 100%;
	padding-left: 1vw;
	/* background-color: green; */
	/* margin-left: 5px; */
}

.pageTitleContainer {
	padding-top: 2vh;
	padding-bottom: 2vh;
	height: 12vh;
}

.pageTitleContainer h1 {
	font-size: max(2vw, 28px);
	line-height: 8vh;
	font-weight: 400;
	color: #3D5DAA;
}

.modalContainer {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0,0.5);
	z-index: 5 !important;
	padding: min(2vw, 2vh);
	overflow-y: auto;
	display: flex;
}

.cancelButton {
	background-color: #FFD600;
	height: 6vh;
	/* width: 10vw; */
	border-radius: 5px;
	border: none;
	outline: none;
	font-size: max(1.2vw, 18px);
	color: #666666;
	font-weight: 400;
}

.saveButton {
	background-color: #6FB54E;
	height: 6vh;
	/* width: 10vw; */
	border-radius: 5px;
	border: none;
	outline: none;
	font-size: max(1.2vw, 18px);
	color: white;
	font-weight: 400;
}

.updateButton {
	background-color: #3D5DAA;
	height: 6vh;
	/* width: 10vw; */
	border-radius: 5px;
	border: none;
	outline: none;
	font-size: max(1.2vw, 18px);
	color: white;
	font-weight: 400;
}

.deleteButton {
	background-color: #F34B4B;
	height: 6vh;
	/* width: 10vw; */
	border-radius: 5px;
	border: none;
	outline: none;
	font-size: max(1.2vw, 18px);
	color: white;
	font-weight: 400;
}

.closeModal {
	position: absolute;
	right: 2vw;
	top: 1vw;
	cursor: pointer;
	z-index: 5;
}

.closeModal img {
	height: 4vh;
	width: 4vh;
}

@media only screen and (max-width: 912px) {
	.mainContainer {
		padding-left: 0;
	}

	.mainContentContainer {
		height: 100vh;
		width: 100%;
		padding-left: 1vw;
	}

	.collapsedMenuMainContainer {
		padding-left: 0;
	}

	.closeModal {
		right: 2vw;
		top: 2vw;
	}
}

@media only screen and (min-width: 1919px) {

}