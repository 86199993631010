.mapLocationModalArea {
	position: relative;
	display: block;
	/* max-width: 500px; */
	width: min(100%, 700px );
	/* width: 100%; */
	height: auto;
	background-color: white;
	margin: auto;
	border-radius: 10px;
	padding: 2vw;
}

.mapLocationModalArea h1 {
	font-size: max(1vw, 16px);
	color: #3D5DAA;
	font-weight: 500;
}

.mapLocationFormInputContainer {
	/* background-color: red; */
	padding-top: 1vh;
	padding-bottom: 1.5vh;
}

.mapLocationFormInputContainer p {
	color: #666666;
	font-size: max(1vw, 16px);
	/* background-color: red; */
	margin-bottom: 0.5vh;
}


.mapLocationFormActionContainer .cancelButton {
	min-width: max( 10vw, 120px );
	margin-right: max( 1vw, 16px );
}

.mapContainer {
	background-color: white;
	width: 100%;
	height: 70vh;
}

.mapContainer img {
	width: max(2vw, 32px);
	height: max(2vw, 32px);
}

@media (max-width: 767px) {

}

@media only screen and (min-width: 1919px) {
	.mapLocationModalArea {
		width: 60vw;
		height: auto;
		max-width: none;
		min-width: none;
		max-height: none;
		min-height: none;
	}
}