.TimesheetEmployeesTableContainer {
	height: 100vh;
	min-height: 100vh;
	max-height: 100vh;
	position: relative;
}

.TimesheetEmployeesTableContainer table {
	width: 100%;
}

.TimesheetEmployeesTableContainer table tr {
	border-bottom: solid 1px #9D9D9D;
}

.TimesheetEmployeesTableContainer table .activeRow {
	background-color: #EAEDF6;
}

.TimesheetEmployeesTableContainer th {
	font-size: max(0.8vw, 14px);
	font-weight: 500;
	color: #666666;
	padding-top: 1vh;
	padding-bottom: 1vh;
	padding-left: 0.5vw;
	padding-right: 0.5vw;
}

.TimesheetEmployeesTableContainer td {
	color: #555555;
	font-size: max(1vw, 16px);
	font-weight: 400;
	padding-top: 1vh;
	padding-bottom: 1vh;
	padding-left: 0.5vw;
	padding-right: 0.5vw;
	cursor: pointer;
}

.employeeStatusButton {
	border-radius: 5px;
	height: 6vh;
	width: 100%;
	border: none;
	outline: none;
	font-size: max(0.8vw, 12px);
	font-weight: 500;
}

.clockedInStatus {
	background-color: #3D5DAA;
	color: white;
}

.clockedOutStatus {
	background-color: #9EAED4;
	color: white;
}

.onLeaveStatus {
	background-color: #FFD600;
	color: #525252;
	font-size: max(0.6vw, 10px);
}

.unknownStatus {
	background-color: grey;
	color: white;
}


.TimesheetEmployeesTableContainer .listActionContainer {
	width: 100%;
	position: absolute;
	bottom: 0.5vh;
}

.TimesheetEmployeesTableContainer.listActionContainer .listActionButton {
	/* margin-left: 1vw; */
	padding-left: 1vw;
	padding-right: 1vw;
	height: 6vh;
	border-radius: 5px;
	border: none;
	outline: none;
}

.TimesheetEmployeesTableContainer .listActionContainer .prevButton {
	background-color: #FFD600;
	color: #525252;
	font-weight: 500;
	font-size: max(14px, 1.2vw);
}

.TimesheetEmployeesTableContainer .listActionContainer .nextButton {
	background-color: #3D5DAA;
	color: white;
	font-weight: 500;
	font-size: max(14px, 1.2vw);
}

@media only screen and (max-width: 912px) {
	.TimesheetEmployeesTableContainer {
		height: auto;
		min-height: 0;
		max-height: initial;
	}
}

@media only screen and (min-width: 1919px) {}