#employeeDetailsContainer .employeeDetailsContainer {
	/* background-color: red; */
	height: 100vh;
	padding-top: 1.5vh;
	border-left: solid 1px black;
}

#employeeDetailsContainer .employeeDetailsModalContainer {
	display: none;
}

#employeeDetailsContainer .editEmployeeRow img {
	height: max(2vw, 32px);
	cursor: pointer;
}

#employeeDetailsContainer .profilePhotoContainer {
	/* background-color: red;	 */
}

#employeeDetailsContainer .profilePhotoContainer img {
	height: max(24vh, 200px);
	width: max(24vh, 200px);
	border-radius: 50%;
}

#employeeDetailsContainer .employeeDetailsTextArea {
	padding-left: 1vw;
	width: 100%;
	height: 64vh;
	padding-top: 3vh;
	overflow-y: auto;
	/* background-color: red; */
}

#employeeDetailsContainer .employeeDetailsTextArea p {
	font-size: max(1vw, 16px);
	font-weight: 400;
	color: #666666;
	width: 100%;
	/* background-color: red; */
	overflow-wrap: break-word;
	/* line-height: 2vh; */
}

#employeeDetailsContainer .employeeDeviceActionArea {
	padding-top: 1vw;
}

#employeeDetailsContainer .userSavedInDevice {
	color: #6FB54E;
	font-size: max(1vw, 16px);
	padding-right: 1vw;
	line-height: max(2vw, 32px);
}

#employeeDetailsContainer .saveUserInDeviceButton {
	border: none;
	outline: none;
	background-color: #FFD600;
	color: #5C5C5C;
	font-size: max(1vw, 16px);
	padding-left: 1em;
	padding-right: 1em;
	border-radius: 5px;
	height: max(2vw, 32px);
	margin-right: 1vw;
}

#employeeDetailsContainer .deleteEmployeeButton {
	border: none;
	outline: none;
	background-color: #F34B4B;
	color: white;
	font-size: max(1vw, 16px);
	padding-left: 1em;
	padding-right: 1em;
	border-radius: 5px;
	height: max(2vw, 32px);
}

@media only screen and (max-width: 768px) {
	#employeeDetailsContainer  {
		padding-top: 20px;
		margin-top: 50px;
		border: none;
	}
}

@media only screen and (max-width: 912px) {
	#employeeDetailsContainer  {
		display: none;
	}
}

@media only screen and (min-width: 1919px) {

}