.loaderScreenContainer {
	background-color: rgba(0,0,0,0.3);
	position: fixed;
	z-index: 100;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	justify-content: center;
}

.loaderContainer {
	height: max(8vh, 54px);
	width: 100%;
	margin-top: max( 46vh, 50vh - 27px );
	justify-content: center;
}

.loaderArea {
	height: 100%;
	width: 100%;
}

.loaderArea svg {
	width: 100%;
	height: 100%;
}