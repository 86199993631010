.addEmployeeRow {
	
}

.addEmployeeRow button {
	background-color: #3D5DAA;
	height: max(6vh, 50px );
	width: max( 15vw, 150px );
	border-radius: 5px;
	border: none;
	outline: none;
	color: white;
	font-size: max(1.1vw, 14px) ;
	font-weight: 500;
}