.pastRequestCard {
	background-color: #ECEFF7;
	margin-top: min( 40px, 1vw );
	/* height: 100%; */
}

.pastRequestCard p {
	font-size: max(1vw, 16px);
	font-weight: 400;
	color: #3C3C3C;
	/* line-height: 1.3vw; */
	margin: 0;
}

.pastRequestCard .requestEmployeeName {
	font-weight: 500;
}

.pastRequestCard .requestFileContainer {
	margin-top: min(1vh, 8px);
}

.pastRequestCard .requestFileContainer img {
	height: max( 1.5vw, 20px ) ;
	margin-right: max(0.3vw, 4px);
}

.pastRequestCard .requestStatusContainer {
	margin-top: min(2vh, 8px);
}

.pastRequestCard .requestStatusContainer button {
	width: 100%;
	margin-right: max(1vw, 6px);
	font-size: max(1vw, 16px );
	border-radius: 5px;
	height: max( 6vh, 40px );
	background: red;
	outline: none;
	border: none;
}

.pastRequestCard .requestStatusContainer .approvedButton {
	background-color: #3D5DAA;
	color: white;
}

.pastRequestCard .requestStatusContainer .rejectedButton {
	background-color: #FFD600;
	color: #666666;
}