.addEmployeeModal {
	
}

.addEmployeeCardContainer {
	height: 100%;
	padding: min(1vh, 1vw);
	/* background-color: green; */
	width: 100%;
	min-height: 100vh;
	margin: 0;
}

.addEmployeeCardContainer .container {
	margin: 0;
	padding: 0;
	max-width: 100% !important;
	width: 100%;
}

.addEmployeeModalArea {
	height: 100%;
	min-height: 94vh;
	width: 100%;
	position: relative;
}

#addEmployeeModal .employeeIdRow p {
	font-size: max( 1.3vw, 16px );
	font-weight: 500;
	color: #666666;
}

.modalProfilePhotoContainer {
	position: relative;
	padding: 2vh;
	width: 35vh;
	height: 35vh;

}

.modalProfilePhotoContainer .editProfilePhotoButton img {
	height: 4vh;
	border-radius: 0;
	position: absolute;
	right: 6vh;
	top: 2vh;
	
}

#addEmployeeModal .modalProfilePhotoContainer .photoArea img {
	border-radius: 50%;
	width: 33vh;
	height: 33vh;
	border: solid 1px #666666;
	object-fit: cover;
}



.inputFormContainer {
	/* background-color: red; */
	width: 100%;
}

.AddEmployeeFormInputContainer {
	/* background-color: red; */
	/* border: solid 1px green; */
}

.AddEmployeeFormInputContainer input {
	border: solid 1px #666666;
	color: #666666;
	font-size: max(1.2vw, 16px );
	font-weight: 400;
	height: 7vh;
}

.AddEmployeeFormInputContainer textarea {
	border: solid 1px #666666;
	color: #666666;
	font-size: max(1vw, 14px);
	font-weight: 400;
	height: 33.5vh;
	width: 100%;
	border-radius: 5px;
	background-color: rgb(236, 239, 247);
	padding-left: 1vw;
	padding-top: 1vh;
}

.AddEmployeeFormInputContainer select {
	border: solid 1px #666666;
	color: #666666;
	font-size: max(1.2vw, 16px);
	font-weight: 400;
	height: 7vh;
	width: 100%;
	border-radius: 10px;
	padding-left: max(1vw, 16px);
	padding-right: max(1vw, 16px);
}

.AddEmployeeFormInputContainer h1 {
	font-size: max(1.2vw, 18px);
	font-weight: 400;
	color: #3D5DAA;
	margin-bottom: 0.5vh;
}

.AddEmployeeFormInputContainer .salaryInputContainer {
	position: relative;
	height: 7vh;
}

.AddEmployeeFormInputContainer .amountText {
	color: #666666;
	font-size: max(1.2vw, 16px );
	font-weight: 400;
	height: 7vh;
	border-radius: 10px;
	border: solid 1px #666666;
	width: 100%;
	line-height: 7vh;
	padding-left: 20px;
	position: absolute;
	z-index: 2;
	background-color: rgb(236, 239, 247);
}

.AddEmployeeFormInputContainer .amountTextInput {
	opacity: 0;
}

.AddEmployeeFormInputContainer .dateInputWrapper {
	width: 100%;
}

.AddEmployeeFormInputContainer .dateInput {
	width: 100%;
}


.salaryInputContainer .amountTextFocused {
	border: solid 2px #666666;
}


.addEmployeeCardContainer .apiResponseText {
	font-size: max(1vw, 16px);
}

.AddEmployeeFormActionContainer .cancelButton {
	min-width: max( 10vw, 88px );
	margin-right: 1vw;
}

.AddEmployeeFormActionContainer .saveButton {
	min-width: max( 10vw, 88px );
}